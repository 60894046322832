import React from 'react'

const Jobs = () => {
  return (
    <div className="Page-Container Extra-Margin-Page-Container">
      <h1>Coming Soon!</h1>
    </div>
  )
}

export default Jobs

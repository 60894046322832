import { useTranslation } from 'react-i18next';
import FooterImg from '../../Assets/karma final logo.svg';
import { Link as ScrollLink} from 'react-scroll';
import { Link as RouterLink,useNavigate} from 'react-router-dom';
import './Footer.css';
const Footer = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const ContactUsNavigation = ()=>{
      navigate('/', { state: { scrollToContactUs: true } });
    }
  return (
    <div className ="Footer">
    <div className="footer-sub-container">
    <img className="FooterLogo" src={FooterImg} alt="Footer Logo" loading="lazy"/>
    <div className={i18n.language === "en" ? "footer-links":"footer-links rtl"}>
      <h4 className="blue-text">{t('Menu')}</h4>
      <RouterLink to="/" className="grey-text">{t('Home')} </RouterLink>
      <RouterLink to="/AboutUs" className="grey-text">{t('About Us')} </RouterLink>
      <RouterLink to="/OurGroups" className="grey-text">{t('Our Groups')} </RouterLink>
      <RouterLink to="/Shop" className="grey-text">{t('Shop')} </RouterLink>
      <RouterLink to="/Products" className="grey-text">{t('Our Products')} </RouterLink>
    <RouterLink to="/Jobs" className="grey-text">{t('Jobs')} </RouterLink>
      <ScrollLink to="ContactUs" className="grey-text"smooth={true} duration={500} onClick={ContactUsNavigation}>{t('Contact Us')}</ScrollLink>
      </div>
      <div className={i18n.language === "en" ? "footer-links":"footer-links rtl"}>
      <h4 className="blue-text">{t('Contact Us')}</h4>
      <RouterLink to="/" className="grey-text ltr">+2 02 448 10 445</RouterLink>
      <RouterLink to="/" className="grey-text ltr">+2 02 448 10 446</RouterLink>
      <RouterLink to="/" className="grey-text">info@karma.com.eg</RouterLink>
    </div>
    <div className={i18n.language === "en" ? "footer-links":"footer-links rtl"}>
      <h4 className="blue-text">{t('Newsletter')}</h4>
     <span className="grey-text">Weekly breaking news,analysis and cutting <br/>edge advices on job searching</span>
     <span className="email-label-newsletter grey-text">Your email address</span>
     <input type="email" className="newsletter-input" name="Email" id="email"placeholder={t('Email Placeholder')}  autoComplete="email"/>
     </div>
    </div>
    <div class="footer-bottom">
    <span className="grey-text copy-right-text" > Copyright &copy; {new Date().getFullYear()} Karma food color , flavours & fragrance All Rights Reserved</span>
    <div className="SocialLinks">
    {/* <a href=" ">
        <i className="fab fa-whatsapp blue-text"></i>
        </a> */}
        <a href="" aria-label="Whatsapp Profile">
        <i className="fab fa-whatsapp blue-text wp-icon"></i>
        </a>
        <a href="https://www.facebook.com/@KarmaAirFreshener/?mibextid=wwXIfr&rdid=JnapCoiqraFp43gp&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F1N7G6x5Zqa%2F%3Fmibextid%3DwwXIfr" aria-label="Facebook Profile">
        <i className="fab fa-facebook blue-text"></i>
        </a>
      
        <a href=" "aria-label="Linkedn Profile">
        <i className="fab fa-pinterest blue-text"></i>
        </a>
        <a href=" " aria-label="Instagram Profile">
        <i className="fab fa-instagram blue-text bold-text"></i>
        </a>
        <a href=" " aria-label="Youtube Profile">
        <i className="fab fa-youtube blue-text bold-text"></i>
        </a>
    </div>
    <p className="grey-text copy-right-text-mobile" > Copyright &copy; {new Date().getFullYear()} Karma food color, <br/>flavours & fragrance All Rights Reserved</p>
    <div className={i18n.language === "en" ? "newsletter-mobile":"newsletter-mobile"}>
      <h4 className="blue-text">{t('Newsletter')}</h4>
     <span className="email-label-newsletter grey-text">{t('Your Email Address')}</span>
     <input type="email" className="newsletter-input" name="Email" id="email"placeholder={t('Email Placeholder')}  autoComplete="email"/>
     </div>
    </div>

    </div>    
  )
}

export default Footer

import { useTranslation } from 'react-i18next';
import {useState, createContext, useContext, useEffect} from 'react';
import Header from './Components/Header/Header.jsx';
import Footer from './Components/Footer/Footer.jsx';
import HomePage from './Components/Home/HomePage.jsx';
import AboutUs from './Components/AboutUs/AboutUs.jsx';
import Shop from './Components/Shop/Shop.jsx';
import OurGroups from './Components/OurGroups/OurGroups.jsx';
import Placeholder from './Components/Placeholder/Placeholder.jsx';
import NotFoundImg from './Assets/404SVG.svg';
import {Route, Routes, useLocation} from 'react-router-dom';
import Jobs from './Components/Jobs/Jobs.jsx';
import './i18n.js';
import './App.css';
export const AppContext = createContext();
function App() {
    const {t, i18n} = useTranslation();
    const [likeCount, setLikeCount] = useState(parseInt(sessionStorage.getItem('likeCount')) || 0);
    const [cartCount, setCartCount] = useState(parseInt(sessionStorage.getItem('cartCount')) || 0);
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
    useEffect(() => {
        i18n.changeLanguage(sessionStorage.getItem('lang') || 'ar');
    }, []);
    return (
        <div className={i18n.language === "en" ? "App drop-back english-font" :"App drop-back arabic-font"}>
            <AppContext.Provider value={{likeCount, setLikeCount, cartCount, setCartCount}}>
                <Header isSearchBoxOpen={isSearchBoxOpen} setIsSearchBoxOpen={setIsSearchBoxOpen}/>
                <Routes>
                    <Route path='/' element={<HomePage/>}/>
                    <Route path='/AboutUs' element={<AboutUs/>}/>
                    <Route path='/OurGroups' element={<OurGroups/>}/>
                    <Route path='/Shop' element={<Shop/>}/>
                    <Route path='/Jobs' element={<Jobs/>}/>
                    <Route path='/*' element={<Placeholder img={NotFoundImg} text={t('Page Not Found')} buttonRoute="/"
                                                           buttonText={t('Home')}/>}/>
                </Routes>
            </AppContext.Provider>
            <Footer/>
        </div>
    );
}

export default App;

import { useState,useEffect,useContext} from 'react';
import { Link as RouterLink,useLocation,useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faBars , faTimes , faGlobe , faSearch ,faUser,faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link as ScrollLink} from 'react-scroll';
import CartIcon from '../../Assets/Cart.svg';
import SearchIcon from '../../Assets/Search.svg';
import LanguageIcon from '../../Assets/language.svg';
import UserIcon from '../../Assets/user.svg';
import HeartIcon from '../../Assets/heart.svg';
import SearchBar from '../SearchBar/SearchBar';
import NavLogo from '../../Assets/karma final logo.svg';
import EgyptFlag from '../../Assets/egypt-flag-icon.svg';
import USAFlag from '../../Assets/united-states-flag-icon.svg';
import {AppContext} from '../../App';
import './Header.css';
const Header = ({isSearchBoxOpen,setIsSearchBoxOpen}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t , i18n }=useTranslation();
    const {likeCount,cartCount}=useContext(AppContext);
    const toggleLanguage = ()=>{
        sessionStorage.setItem('lang', i18n.language === 'en' ? 'ar' : 'en');
        i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');
    }
    const mobileNavBarHandler = ()=>{
      setIsMenuOpen(!isMenuOpen);
      setIsSearchBoxOpen(false);
    }
    const searchBoxHandler = ()=>{
      setIsSearchBoxOpen(true);
      setIsMenuOpen(false);
    }
    const closeSearchBox = ()=>{
      setIsSearchBoxOpen(false);
      setIsMenuOpen(false);
    }
    const ContactUsNavigation = ()=>{
      navigate('/', { state: { scrollToContactUs: true } });
    }
    useEffect(()=>{
      setIsMenuOpen(false);
    },[location])
    useEffect(() => {
      window.addEventListener("scroll", closeSearchBox);
      return () => {
        window.removeEventListener("scroll", closeSearchBox);
      };
    }, [isMenuOpen]);
      return (
    <div className = {i18n.language === "en"? 'ltr':'rtl'}>
        
        <div className="NavBardiv">
    <div className="NavBarContainer">
       <RouterLink to="/">
        <img className="NavLogo"src={NavLogo} alt="Navigation Bar Logo" loading="eager"/>
        </RouterLink>
    <div className="NavBar" >
      <div className="sub-nav-bar">
          <RouterLink to="/" className={location.pathname === '/' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Home')} </RouterLink>
        <div className="dropdown">
        <RouterLink to="/AboutUs" className={location.pathname === '/AboutUs' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('About Us')} </RouterLink>
         <div className={i18n.language === "en" ? "dropdown-content ltr" :"dropdown-content rtl"}>
         <RouterLink to="/OurCustomers"  className={location.pathname === '/OurCustomers' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Our Customers')}</RouterLink>
         <RouterLink to="/Certifications" className={location.pathname === '/Certifications' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Certifications')} </RouterLink>
        </div>
      </div>
      
        <RouterLink to="/OurGroups" className={location.pathname === '/OurGroups' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Our Groups')} </RouterLink>
        <div className="dropdown">
        <RouterLink to="/Shop" className={location.pathname === '/Shop' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Shop')} </RouterLink>
         <div className={i18n.language === "en" ? "dropdown-content ltr" :"dropdown-content rtl"}>
         <RouterLink to="/Shop/Sale"  className={location.pathname === '/Shop/Sale' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Sale')}</RouterLink>
         <RouterLink to="/MyOrders"  className={location.pathname === '/MyOrders' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('My Orders')}</RouterLink>
        </div>
      </div>
      <div className="dropdown">
        <RouterLink to="/Products" className={location.pathname === '/Products' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Our Products')} </RouterLink>
         <div className={i18n.language === "en" ? "dropdown-content ltr" :"dropdown-content rtl"}>
         <RouterLink to="/ConsumerProducts"  className={location.pathname === '/ConsumerProducts"' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Consumer Products')}</RouterLink>
         <RouterLink to="/IndustrialProducts" className={location.pathname === '/IndustrialProducts' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Industrial Products')} </RouterLink>
        </div>
      </div>
        {/* <RouterLink to="/B2B" className={location.pathname === '/B2B' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('B2B')} </RouterLink> */}
        <RouterLink to="/Jobs" className={location.pathname === '/Jobs' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Jobs')} </RouterLink>
        <ScrollLink to="ContactUs" smooth={true} duration={500} className={location.pathname === '/ContactUs' ? 'ActiveNavBar LinkStyle' : 'LinkStyle'} onClick={ContactUsNavigation}>{t('Contact Us')}</ScrollLink>
        </div>
        <div>
        </div>

        </div>

        <div className="Header-icons">
        <img src={SearchIcon} alt="Search Icon" style={{width: '26px'}} onClick={searchBoxHandler} loading="eager"/>
        <div className="like-container">
      {/* <FontAwesomeIcon icon={faHeart} style={{ fontSize: '27px'}} /> */}
      <img src={HeartIcon} alt="Heart Icon" style={{width: '26px'}} loading="eager"/>
      <div className="like-count">
       {likeCount > 99 ? "+99" :likeCount}
      </div>
    </div>
    <div className="like-container">
    <img src={CartIcon} alt="Cart Icon" style={{width: '30px'}} loading="eager"/>
    <div className="like-count">
       {cartCount > 99 ? "+99" :cartCount}
      </div>
    </div>
    <img src={UserIcon} alt="User Icon" style={{width: '26px'}}/>
    {/* <img src={LanguageIcon} alt="Language Icon" style={{width: '26px'}} onClick={toggleLanguage}/> */}
    <div style={{ width: '1px', height: '20px', backgroundColor: '#ccc',marginTop:"5px",marginLeft:"5px",marginRight:"5px"}}></div>
    <img src={i18n.language === "en"? USAFlag : EgyptFlag} alt="Language Icon" style={{width: '26px'}} onClick={toggleLanguage}/>
    </div>
      </div>
</div>
<div className="MobileNavBarContainer">
<div className="MobileNavBar">
          <div>
        <FontAwesomeIcon className="mobile-nav-icon" icon={isMenuOpen ? faTimes : faBars} style={{ color: 'Black', fontSize: isMenuOpen ? '20px ':'18px'}} onClick={mobileNavBarHandler}/>
        <FontAwesomeIcon className="mobile-nav-icon" icon= {faSearch} style={{ color: 'Black', fontSize: '18px'}} onClick={searchBoxHandler}/>
        <FontAwesomeIcon className="mobile-nav-icon" icon={faUser} style={{ fontSize: '18px' }}/>
          </div>
          <RouterLink to="/">
        <img className="NavLogo" src={NavLogo} alt="Navigation Bar Logo" loading="eager"/>
          </RouterLink>
          <div>
          <div className="like-container mobile-nav-icon">
      <FontAwesomeIcon icon={faHeart} style={{ fontSize: '20px'}} />
      <div className="like-count like-count-mobile">
       {likeCount > 99 ? "+99" :likeCount}
      </div>
    </div>
    <div className="like-container mobile-nav-icon">
      <FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: '18px' }} />
      <div className="like-count like-count-mobile">
       {cartCount > 99 ? "+99" :cartCount}
      </div>
    </div>
      {/* <FontAwesomeIcon className ="mobile-nav-icon" icon= {faGlobe} style={{ color: 'black', fontSize: '18px'}} onClick={toggleLanguage}/> */}
      <img src={i18n.language === "en"? USAFlag : EgyptFlag} alt="Language Icon" style={{width: '20px'}} onClick={toggleLanguage}/>

          </div>
        </div>
    {isMenuOpen && <div id="MobileNavBarActive" className="MobileNavBarActive" >
      <RouterLink to="/" className={location.pathname === '/' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'}>{t('Home')} </RouterLink>
        <RouterLink to="/AboutUs" className={location.pathname === '/AboutUs' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'}>{t('About Us')} </RouterLink>
        <RouterLink to="/OurGroups" className={location.pathname === '/OurGroups' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'}>{t('Our Groups')} </RouterLink>
        <RouterLink to="/OurCustomers" className={location.pathname === '/OurCustomers' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'}>{t('Our Customers')}</RouterLink>
        <RouterLink to="/Shop" className={location.pathname === '/Shop' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'}>{t('Shop')} </RouterLink>
        <RouterLink to="/Products" className={location.pathname === '/Products' ? 'active-link LinkStyle' : 'LinkStyle'}>{t('Our Products')} </RouterLink>
        {/* <RouterLink to="/B2B" className={location.pathname === '/B2B' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'}>{t('B2B')} </RouterLink> */}
        <RouterLink to="/Certifications" className={location.pathname === '/Certifications' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'}>{t('Certifications')} </RouterLink>
        <RouterLink to="/Jobs" className={location.pathname === '/Jobs' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'}>{t('Jobs')} </RouterLink>
        <ScrollLink to="ContactUs" smooth={true} duration={500} className={location.pathname === '/ContactUs' ? 'ActiveNavBarMobile LinkStyle' : 'LinkStyle'} onClick={ContactUsNavigation}>{t('Contact Us')}</ScrollLink>

    </div> }
    </div>
    {isSearchBoxOpen &&
      <SearchBar closeSearchBox={closeSearchBox}/>
      }
</div>
  )
}

export default Header

import { NavLink } from 'react-router-dom';
import './HomeCard.css';
const HomeCard = ({image ,title,path,isHoverable}) => {
  return (
    <NavLink to={path}>
      <div className="custom-category-card">
              <img src={image}alt={title==""?"Card Image":title} className="custom-img-fluid" loading="lazy"/>
              <h5 className="initial-text">{title}</h5>
           {isHoverable &&   
           <div className="custom-overlay">
                <div className="custom-text">
                </div>
              </div>}
            </div>
      </NavLink>
  )
}

export default HomeCard;

import HomeCard from "../HomeCard/HomeCard";
import './CategoryList.css';
const CategoryList = ({categories}) => {
  return (
    <div className="card-category-row">
      {categories.map((category, index) => (
        <div className="col-md-3" key={index}>
          <HomeCard title={category.title} image = {category.image} path="/OurGroups" isHoverable={true}/>
        </div>
      ))}
  </div>

  )
}

export default CategoryList

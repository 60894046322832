import './SearchBar.css';
import { useTranslation  } from 'react-i18next';
const SearchBar = ({closeSearchBox}) => {
    const [ t, i18n ] = useTranslation();
    const handleSearch =()=>{
        
    }
  return (
    <div className = "search-bar-container">
        {/* <form onSubmit={handleSearch} > */}
        {/* onChange={(e) => setQuery(e.target.value)} */}
        <div className="searchbar-sub-container">
          <input
            type="text"
            // value={query}
            className="search-input"
            placeholder={t('Search Placeholder')}
          />
          <div className="search-btns-container">
           <button>
            {t('Search')}
           </button>
           <button onClick={closeSearchBox} id="cancel-btn">
           {t('Cancel')}
           </button>
          </div>
      
        {/* </form> */}
        </div>
    </div>
  )
}

export default SearchBar

import { useTranslation } from "react-i18next";
import AboutImg from '../../Assets/HomeAboutImg.jpg';
import AboutImg2 from '../../Assets/AboutImg2.jpg';
import AboutImg3 from '../../Assets/AboutImg3.jpg';

import './AboutUs.css';
const AboutUs = () => {
  const [t,i18n] = useTranslation();
  return (
    <div className="Page-Container Extra-Margin-Page-Container">
     {i18n.language == "en"?
     <div> 
      <div className="Home-About-Container">
      <img src={AboutImg} alt="About" className="Home-About-Image" loading="lazy"/> 
      <p className="about-us-paragraph">
        <h1 className="about-title regular-english-font">About Us</h1>
      Karma Company was founded by the Zawawy brothers in 2005, with its headquarters located in El Obour City, Egypt. The company specializes in food colors, flavors, and fragrances, and has gained a strong reputation in the Egyptian market due to its growing expertise.
      Since its inception, Karma has become one of the largest manufacturers of high-quality colors used in pharmaceutical and food products. The company's sectors of operation include the production of food colors, flavor development, and fragrance manufacturing. It has also strengthened its market share in the fragrance sector by producing the finest fragrances for toilet soap, detergents, and air fresheners. Additionally, we excel in innovating high-quality flavors used in pharmaceutical and food industries. The continuous growth of the company reflects its commitment to quality and innovation, making it a leader in this field.
      </p>
      </div>
      <div className="Home-About-Container">
      <p className="about-us-paragraph">
      <h1 className="about-title regular-english-font">Our Vision</h1>
      Karma strives to achieve the highest level of quality in its services, as it has been certified under the ISO 9001 quality management system, ensuring continuous improvement in operations to meet our clients' needs. Furthermore, the company has obtained certification for the ISO 22001 food safety management system. Karma has also received the "Made in Egypt" certification as one of the leading Egyptian companies in the industry.
      Karma carefully selects the best international suppliers to provide raw materials that meet the required standards for its product range. All raw materials undergo rigorous testing to ensure compliance with the highest quality standards and are subject to strict control until they pass our quality assurance criteria.

      </p>
      <img src= {AboutImg2 } alt="About" className="Home-About-Image" loading="lazy"/> 
      </div>
      <div className="Home-About-Container">
      <img src={AboutImg3} alt="About" className="Home-About-Image" loading="lazy"/> 
      <p className="about-us-paragraph">
      <h1 className="about-title regular-english-font">Our Mission</h1>
      Karma carefully selects the best international suppliers to provide raw materials that meet the required standards for its product range. All raw materials undergo rigorous testing to ensure compliance with the highest quality standards and are subject to strict control until they pass our quality assurance criteria.
      Karma owns seven brands, which include color and flavor enhancers, spices, powdered juices, household care products, personal care products, cosmetic products, and versatile home fragrances. Karma continues to innovate and expand, reflecting its commitment to quality and excellence in all its products.
      </p>
      </div>
     </div>:<div className="rtl">
     <div className="Home-About-Container">
     <img src={AboutImg} alt="About" className="Home-About-Image"loading="lazy"/> 
     <p className="about-us-paragraph">
     <h1 className="about-title">من نحن</h1>
تأسست شركة كارما على يد الأخوين الزواوي في عام 2005، ومقرها مدينة العبور، مصر. تتخصص الشركة في مجال ألوان الطعام والنكهات والعطور، وقد اكتسبت سمعة قوية في السوق المصرية بفضل خبرتها المتزايدة.
منذ تأسيسها، أصبحت كارما واحدة من أكبر الشركات المصنعة للألوان عالية الجودة المستخدمة في المنتجات الصيدلانية والغذائية. تشمل قطاعات عمل الشركة صناعة ألوان الطعام، إنتاج النكهات، وتصنيع العطور، المنظفات، ومعطرات الهواء. كما نتميز بابتكار نكهات عالية الجودة تُستخدم في الصناعات الدوائية والغذائية. يعكس النمو المستمر للشركة التزامها بالجودة والابتكار، مما يجعلها رائدة في هذا المجال.

      </p>
      </div>
      <div className="Home-About-Container">
      <p className="about-us-paragraph">
      <h1 className="about-title">رؤيتنا</h1>
      تسعى كارما لتحقيق أعلى مستوى من الجودة في خدماتها، حيث تم اعتمادها لنظام إدارة الجودة ISO 9001، مما يضمن تحسين مستمر في العمليات لتلبية احتياجات عملائنا. علاوة على ذلك، حصلت الشركة على اعتماد نظام إدارة سلامة الغذاء ISO 22001. كما حصلت كارما على اعتماد "صنع في مصر" كواحدة من الشركات المصرية الرائدة في الصناعة.
      تختار كارما بعناية أفضل الموردين الدوليين لتوفير المواد الخام التي تلبي المعايير المطلوبة لمجموعة منتجاتها. يتم فحص جميع المواد الخام بشكل مكثف لضمان امتثالها لأعلى معايير الجودة، وتخضع لرقابة صارمة حتى تجتاز معايير ضمان الجودة لدينا.

      </p>
      <img src={AboutImg2} alt="About" className="Home-About-Image" loading="lazy"/> 
      </div>
      <div className="Home-About-Container">
      <img src={AboutImg3} alt="About" className="Home-About-Image" loading="lazy"/> 
      <p className="about-us-paragraph">
      <h1 className="about-title">مهمتنا</h1>
      تملك كارما سبع علامات تجارية تشمل مكسبات الألوان والطعم، والتوابل، والعصير البودرة، ومنتجات العناية المنزلية، ومنتجات العناية الشخصية، ومنتجات التجميل، والمعطرات المنزلية متعددة الاستخدامات. تستمر كارما في الابتكار والتوسع، مما يعكس التزامها بالجودة والتميز في جميع منتجاتها.
      </p>
     </div>
     </div>
     }
        {i18n.language === "en" ? 
     <div>
      <div className="Mobile-About-Container">
      <img src={AboutImg} alt="About" className="Home-About-Image Mobile-About-Img" loading="lazy"/> 

      <p className="Mobile-About-paragraph">
      <h1 className="about-title about-title-mobile regular-english-font">About Us</h1>
           Karma Company was founded by the Zawawy brothers in 2005, with its headquarters located in El Obour City, Egypt.
           The company specializes in food colors, flavors, and fragrances, and has gained a strong reputation in the 
           Egyptian market due to its growing expertise
        </p>
        <p className="Mobile-About-paragraph">Since its inception, Karma has become one of the largest manufacturers 
           of high-quality colors used in pharmaceutical and food products. The company's sectors of operation include the 
           production of food colors, flavor development, and fragrance manufacturing. It has also strengthened its market 
           share in the fragrance sector by producing the finest fragrances for toilet soap, detergents, and air fresheners. 
           Additionally, we excel in innovating high-quality flavors used in pharmaceutical and food industries. The continuous 
           growth of the company reflects its commitment to quality and innovation, making it a leader in this field.
        </p> 
        </div>
        
        <div className="Mobile-About-Container">
        <img src={AboutImg2} alt="About" className="Home-About-Image Mobile-About-Img" loading="lazy"/> 
        <p className="Mobile-About-paragraph">
        <h1 className="about-title about-title-mobile regular-english-font">Our Vision</h1>
          Karma strives to achieve the highest level of quality in its services, as it has been certified under the
           ISO 9001 quality management system, ensuring continuous improvement in operations to meet our clients' 
           needs. Furthermore, the company has obtained certification for the ISO 22001 food safety management system. 
           Karma has also received the "Made in Egypt" 
          certification as one of the leading Egyptian companies in the industry.
        </p> 
        </div>
        <div className="Mobile-About-Container">
        <img src={AboutImg3} alt="About" className="Home-About-Image" loading="lazy"/> 
        <p className="Mobile-About-paragraph">
        <h1 className="about-title about-title-mobile regular-english-font">Our Mission</h1>
            Karma carefully selects the best international suppliers to provide raw materials that meet the 
            required standards for its product range. All raw materials undergo rigorous testing to ensure 
            compliance with the highest quality standards and are subject to strict 
            control until they pass our quality assurance criteria.
        </p> 
        <p className="Mobile-About-paragraph">
          Karma owns seven brands, which include color and flavor enhancers, spices, 
          powdered juices, household care products, personal care products, cosmetic 
          products, and versatile home fragrances. Karma continues to innovate and expand, reflecting its 
          commitment to quality and excellence in all its products.
        </p> 
        </div>

        </div>
          :
        <div className="rtl">
        <div className="Mobile-About-Container">
        <img src={AboutImg} alt="About" className="Home-About-Image Mobile-About-Img" loading="lazy"/> 
        <p className="Mobile-About-paragraph">
        <h1 className="about-title about-title-mobile">من نحن</h1>
          تأسست شركة كارما على يد الأخوين الزواوي في عام2005،
           ومقرها مدينة العبور، مصر. تتخصص الشركة في مجال ألوان 
           والنكهات والعطور، وقد اكتسبت سمعة قوية
           في السوق المصرية بفضل خبرتها المتزايدة
        </p>
        <p className="Mobile-About-paragraph">    
        منذ تأسيسها، أصبحت كارما واحدة من
      أكبر الشركات المصنعة للألوان عالية الجودة المستخدمة في المنتجات الصيدلانية والغذائية.
      تشمل قطاعات عمل الشركة صناعة ألوان الطعام، إنتاج النكهات، وتصنيع العطور، المنظفات
      ، ومعطرات الهواء. كما نتميز بابتكار نكهات عالية الجودة تُستخدم
      في الصناعات الدوائية والغذائية. يعكس النمو المستمر للشركة 
      التزامها بالجودة والابتكار، مما يجعلها رائدة في هذا المجال.
        </p> 
        </div>
        <div className="Mobile-About-Container">
        <img src={AboutImg2} alt="About" className="Home-About-Image Mobile-About-Img" loading="lazy"/> 
        <p className="Mobile-About-paragraph">    
        <h1 className="about-title about-title-mobile">رؤيتنا</h1>
        تسعى كارما لتحقيق أعلى مستوى من الجودة في خدماتها، حيث تم اعتمادها لنظام إدارة الجودة 
        ISO 9001، مما يضمن تحسين مستمر في العمليات لتلبية
         احتياجات عملائنا. علاوة على ذلك، حصلت الشركة على اعتماد نظام إدارة سلامة الغذاء ISO 22001. 
         كما حصلت كارما على اعتماد "صنع في مصر" كواحدة من الشركات المصرية الرائدة في الصناعة.
        </p> 
        <p className="Mobile-About-paragraph">    
        تختار كارما بعناية أفضل الموردين الدوليين لتوفير المواد الخام التي تلبي المعايير 
        المطلوبة لمجموعة منتجاتها. يتم فحص  المواد الخام بشكل مكثف لضمان
         امتثالها لأعلى معايير الجودة، وتخضع لرقابةصارمة حتى تجتاز معايير ضمان الجودة لدينا.
        </p> 
        </div>
        <div className="Mobile-About-Container">
        <img src={AboutImg3} alt="About" className="Home-About-Image Mobile-About-Img" loading="lazy"/> 
        <p className="Mobile-About-paragraph">
        <h1 className="about-title about-title-mobile">مهمتنا</h1>    
          تملك كارما سبع علامات تجارية تشمل مكسبات الألوان والطعم، والتوابل، والعصير البودرة، 
          ومنتجات العناية المنزلية، ومنتجات العناية الشخصية، ومنتجات التجميل، والمعطرات المنزلية متعددة الاستخدامات. تستمر كارما
           في الابتكار والتوسع، مما يعكس التزامها بالجودة والتميز في جميع منتجاتها.
        </p>
        </div>
        </div>
}
      </div> 
  )
}

export default AboutUs

import { useTranslation } from 'react-i18next';
import { useLocation,NavLink } from 'react-router-dom';
import { useEffect} from 'react';
import ContactUs from '../ContactUsForm/ContactUs';
import CategoryList from '../CategoryList/CategoryList';
import OverlayImg from '../../..//src/Assets/OverlayImg.png';
import HomeAboutImg from '../../Assets/HomeAboutImg.jpg';
import './HomePage.css';
const HomePage = () => {
  const [t , i18n] = useTranslation();
  const location = useLocation();
  const categories = t('productsCategories', { returnObjects: true });
  useEffect(() => {
    if (location.state?.scrollToContactUs) {
      const element = document.getElementById('ContactUs');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <div className="Page-Container">
      <div className="Hero-Section Home-gif">
    <video className="Home-gif" preload="auto" autoPlay muted loop playsInline poster="https://karma-eg.com/Video/Video Thumbnail.png" >
     <source src={"https://karma-eg.com/Video/Video.mp4"}  type="video/mp4" />
     </video> 
     <img src={OverlayImg} alt="Overlay Image" class="overlay-image" loading="eager"/>
{/* <iframe 
  
  src="https://streamable.com/e/nfuyt4?autoplay=1&controls=0&mute=1" 
  width="99%" 
  height="100%" 
  allow="autoplay; fullscreen">
</iframe>  */}
      </div> 

      <div className="About-Section section">
       <div className="About-Section section">
      <h1 className={i18n.language === "en" ? 'title regular-english-font' :'title'}>{t('About Us')}</h1>
       <div className ={i18n.language === "en" ? 'ltr Home-About-Container' :'rtl Home-About-Container'}>
         <img src={HomeAboutImg} alt="" className="Home-About-Image" loading="lazy"/>
        {i18n.language === "en" ? 
        <p>Karma Company was founded by the Zawawy brothers in 2005, with its headquarters located in El Obour City, Egypt.
           The company specializes in food colors, flavors, and fragrances, and has gained a strong reputation in the 
           Egyptian market due to its growing expertise.<br /><br />Since its inception, Karma has become one of the largest manufacturers 
           of high-quality colors used in pharmaceutical and food products. The company's sectors of operation include the 
           production of food colors, flavor development, and fragrance manufacturing. It has also strengthened its market 
           share in the fragrance sector by producing the finest fragrances for toilet soap, detergents, and air fresheners. 
           Additionally, we excel in innovating high-quality flavors used in pharmaceutical and food industries. The continuous 
           growth of the company reflects its commitment to quality and innovation, making it a leader in this field.
        </p> :
          <p>
          تأسست شركة كارما على يد الأخوين الزواوي في عام 2005، ومقرها مدينة العبور، مصر.
          تتخصص الشركة في مجال ألوان الطعام والنكهات والعطور، وقد اكتسبت سمعة قوية
          في السوق المصرية بفضل خبرتها المتزايدة.<br /><br />
          منذ تأسيسها، أصبحت كارما واحدة من أكبر الشركات المصنعة للألوان عالية الجودة
          المستخدمة في المنتجات الصيدلانية والغذائية. تشمل قطاعات عمل الشركة صناعة ألوان
          الطعام، إنتاج النكهات، وتصنيع العطور، المنظفات، ومعطرات الهواء. كما نتميز بابتكار
          نكهات عالية الجودة تُستخدم في الصناعات الدوائية والغذائية. يعكس النمو المستمر
          للشركة التزامها بالجودة والابتكار، مما يجعلها رائدة في هذا المجال.
          </p>
}
      </div> 
      <div className ={i18n.language === "en" ? 'ltr Mobile-Home-About-Container' :'rtl Mobile-Home-About-Container'}>
      <img src={HomeAboutImg} alt="About" className="Home-About-Image Mobile-About-Img" loading="lazy"/> 
        {i18n.language === "en" ? 
        <p>Karma Company was founded by the Zawawy brothers in 2005, with its headquarters located in El Obour City, Egypt.
           The company specializes in food colors, flavors, and fragrances, and has gained a strong reputation in the 
           Egyptian market due to its growing expertise.
        </p> :
          <p>
          تأسست شركة كارما على يد الأخوين الزواوي في عام 2005، ومقرها مدينة العبور، مصر.
          تتخصص الشركة في مجال ألوان الطعام والنكهات والعطور، وقد اكتسبت سمعة قوية
          في السوق المصرية بفضل خبرتها المتزايدة.
          </p>
}
      </div> 
      <div className="btn-container">
        <NavLink to ="/AboutUs"><button className="learn-more-btn">{t('Learn More')}</button></NavLink>
        </div>
      </div> 
      <hr/>
      <div className="Products-Section section">
      <h1 className="title home-our-products-title">{t('Our Products')}</h1>
      <CategoryList categories={categories}/>
     </div>
     
       <hr id = "ContactUs"/>
      <div className="Contact-us-section section" >
      <h1 className="title">{t('Contact Us')}</h1>
      <ContactUs/>
      </div>
      <hr/>
      <div className="LocationSection section" >
       
      <h1 className="title">{t('Location Section Title')}</h1>
      <p className="location-text">{t('Location 1')}<br/>{t('Location 2')}</p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.9355599750565!2d31.450138900000002!3d30.181834699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14581ba153737863%3A0x7ecb63389a98c0c6!2sKarma%20For%20Food%20colors%2C%20Flavors%20and%20Fragrances!5e0!3m2!1sen!2seg!4v1735047575903!5m2!1sen!2seg"
       style={{ border: 0 }} title="Map"/>
      </div>
      
      </div>
      </div>
    
  )
}

export default HomePage

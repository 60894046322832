import { useTranslation } from 'react-i18next';
import './Shop.css';
const Shop = () =>{
  const [t,i18n] = useTranslation();
  return (
    <div className = "Page-Container Extra-Margin-Page-Container">
    {/* <ShopCarousel className="shop-carousel" /> */}
    <h1>Coming Soon ...</h1>
    </div>
)
};

export default Shop;

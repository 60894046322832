import { useTranslation } from 'react-i18next'
import './ContactUs.css'
const ContactUs = () => {
    const [ t , i18n ] = useTranslation();
  return (
    <form  className={i18n.language === "en" ? " ContactForm ltr " :" ContactForm rtl "} id="ContactUsForm" >
      <div class="form-sub-container">
      <div className={i18n.language === "en" ? " input-container-en " :"input-container-ar "}>
<label htmlFor="Name" >{t('Name')} </label>
    <input type="Text" name="Name" id="Name"  placeholder={t('Name Placeholder')} autoComplete="Name"/>
</div>
<div className={i18n.language === "en" ? " input-container-en " :"input-container-ar "}>
  <label  htmlFor="MobileNumber">{t('Mobile Number')}</label>
  <input type="text" name="MobileNumber" id="MobileNumber" placeholder={t('Mobile Number Placeholder')}/>
    </div>
    <div className={i18n.language === "en" ? " input-container-en " :"input-container-ar "}>
  <label  htmlFor="email">{t('Email')}</label>
<input type="email" name="Email" id="email"placeholder={t('Email Placeholder')}  autoComplete="email"/>
</div>
<div className={i18n.language === "en" ? " input-container-en " :"input-container-ar "}>
  <label htmlFor="ContactMessage">{t('Message')}</label>
<textarea name="Message" id="ContactMessage" placeholder={t('Message Placeholder')} ></textarea>  
</div>
</div>
<button type="submit" className="Button">{t('Send')}</button>
</form>
  )
}

export default ContactUs
